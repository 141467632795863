import React from 'react';

import { CommonLayout } from '../../components/Layout/CommonLayout';
import {
  FirstScreen,
  CompressionScreen,
  CropAndResizeScreen,
  OperationsScreen,
} from '../../components/forImageProccessing';
import { PlatformScreen } from '../../components/PlatformScreen';
import { FinalCTA } from '../../components/FinalCTA';
import { frontmatter as originalFrontmatter } from '../cdn/image-processing';
import { Navbar, NavbarModesEnum } from '../../components/Navbar';

export const frontmatter = {
  canonicalUrl: 'https://uploadcare.com/cdn/image-processing/',
  noindex: true,
};

const ImageProcessing = ({ location }) => (
  <CommonLayout
    meta={{
      ...originalFrontmatter,
      ...frontmatter,
    }}
    pathName={location.pathname}
  >
    <Navbar mode={NavbarModesEnum.PPC} />
    <FirstScreen ppc />
    <CompressionScreen />
    <CropAndResizeScreen />
    <OperationsScreen ppc />
    <PlatformScreen heading="The edge of an entire file handling system" />
    <FinalCTA ppc />
  </CommonLayout>
);

export default ImageProcessing;
